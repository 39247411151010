import Cookies from 'js-cookie'

export enum ESchemeType {
  'APP_PAGE' = 1,
  'APP_H5_PAGE' = 2, // 会在app内打开新的webview容器
  'OUT_APP_PAGE' = 3
}

export enum EReturnType {
  'LOAD' = 0,
  'APP_PAGE' = 1,
  'APP_H5_PAGE' = 2, // 会在app内打开新的webview容器
  'OUT_APP_PAGE' = 3
}

const C5_CHANNEL = Cookies.get('C5Channel')?.toLowerCase?.()

const isiOS = C5_CHANNEL === 'ios'
const isAndroid = !!C5_CHANNEL && !isiOS

// (isiOS || isAndroid) &&
// 使用APP中引入的cookie，且是与登录状态无关的cookie判断h5页面是否运行在app中
export const isInApp: boolean = !!Cookies.get('C5AppVersion')

const native = {
  // 唤起app登录
  login() {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.login?.postMessage([])
    } else if (isAndroid) {
      ;(window as any)?.android?.login()
    }
  },
  // 唤起登录，登录后跳转页面
  loginBackPage(url: string) {
    if (isiOS) {
      console.log('loginBackpage - ios', url)
      ;(window as any)?.webkit?.messageHandlers?.login2?.postMessage([url])
      console.log('调用过了login2')
    } else if (isAndroid) {
      console.log('loginBackpage - android', url, (window as any)?.android?.login2)
      ;(window as any)?.android?.login2(url)
      console.log('调用过了login2')
    }
  },
  // 使用app提供的跳转功能
  scheme(type: ESchemeType, url: string) {
    console.log('scheme', type, url)
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.scheme?.postMessage([type, url])
    } else if (isAndroid) {
      ;(window as any)?.android?.scheme(type, url)
    }
  },
  // 使用app提供的跳转功能-供三方使用
  thirdScheme(type: ESchemeType, url: string) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.scheme?.postMessage([type, url])
    } else if (isAndroid) {
      ;(window as any)?.c5Android?.scheme(type, url)
    }
  },
  // app震动
  toVibrator() {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.toVibrator?.postMessage([])
    } else if (isAndroid) {
      ;(window as any)?.android?.toVibrator()
    }
  },
  // app关闭webview
  back() {
    console.log('native back called')
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.back?.postMessage([])
    } else if (isAndroid) {
      ;(window as any)?.android?.back()
    }
  },
  // app关闭webview-供三方使用
  thirdBack() {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.back?.postMessage([])
    } else if (isAndroid) {
      ;(window as any)?.c5Android?.back()
    }
  },
  // 刷新steam登录信息
  refreshSteamInfo(steamId: string) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.refreshSteamInfo?.postMessage([steamId])
    } else if (isAndroid) {
      ;(window as any)?.android?.refreshSteamInfo(steamId)
    }
  },
  // 隐藏App头部导航
  hiddenToolBar() {
    console.log('hiddenToolBar called')
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.hiddenToolBar?.postMessage?.([])
    } else if (isAndroid) {
      ;(window as any)?.android?.hiddenToolBar?.()
    }
  },
  // 旧充值
  rechargeOld({ chooseCoin, payChannel, returnUrl, rechargeToCoin }: any) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.rechargeNew?.postMessage([chooseCoin, payChannel, returnUrl, rechargeToCoin])
    } else if (isAndroid) {
      ;(window as any)?.android?.rechargeNew(chooseCoin, payChannel, returnUrl, rechargeToCoin)
    }
  },
  // 新充值
  //
  rechargeNew({
    payAmount,
    terminalBusinessId,
    business,
    returnUrl,
    rechargeToCoin,
    returnType
  }: {
    payAmount: string
    terminalBusinessId: string
    business: string
    returnUrl: string
    rechargeToCoin: '0' | '1' // false or true
    returnType?: EReturnType | { ios: EReturnType; android: EReturnType }
  }) {
    const version = Number(Cookies.get('C5AppVersion') || '401000')

    console.log(
      'native-rechargeNew方法：',
      'appVersion',
      version,
      'payAmount',
      payAmount,
      'terminalBusinessId',
      terminalBusinessId,
      'business',
      business,
      'returnUrl',
      returnUrl,
      'rechargeToCoin',
      rechargeToCoin,
      'returnType',
      returnType
    )

    if (isiOS) {
      if (version >= 402070) {
        const iosReturnType = typeof returnType === 'object' && returnType.ios !== undefined ? returnType.ios : returnType
        ;(window as any)?.webkit?.messageHandlers?.rechargeNew?.postMessage([
          payAmount,
          terminalBusinessId,
          business,
          returnUrl,
          rechargeToCoin,
          '', // extJson
          iosReturnType
        ])
      } else {
        ;(window as any)?.webkit?.messageHandlers?.rechargeNew?.postMessage([
          payAmount,
          terminalBusinessId,
          business,
          returnUrl,
          rechargeToCoin
        ])
      }
    } else if (isAndroid) {
      if (version >= 402070) {
        const androidReturnType = typeof returnType === 'object' && returnType.android !== undefined ? returnType.android : returnType
        ;(window as any)?.android?.rechargeNew(payAmount, terminalBusinessId, business, returnUrl, rechargeToCoin, androidReturnType)
      } else {
        ;(window as any)?.android?.rechargeNew(payAmount, terminalBusinessId, business, returnUrl, rechargeToCoin)
      }
    }
  },
  // 更改APP头部title
  changeTitle(title: string) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.changeTitle?.postMessage([title])
    } else if (isAndroid) {
      ;(window as any)?.android?.changeTitle(title)
    }
  },
  // 更改APP头部右侧配置
  // type: 1为App内部，2为App内部H5，3为外部浏览器，11h5专属，执行js方法
  setToolRightView(title: string, type: 1 | 2 | 3 | 11, url: string) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.setToolRightView?.postMessage([title, type, url])
    } else if (isAndroid) {
      ;(window as any)?.android?.setToolRightView(title, type, url)
    }
  },
  // 分享浮层
  share(url: String, title: String, des: String, imageUrl: String) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.share?.postMessage([url, title, des, imageUrl])
    } else if (isAndroid) {
      ;(window as any)?.android?.share(url, title, des, imageUrl)
    }
  },
  // 保存图片
  // shareType: wechat-微信，moments-朋友圈，qq-QQ，saveImage-保存图片
  shareImageWithBase64(shareType: String, imageBase64: String) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.shareImageWithBase64?.postMessage([shareType, imageBase64])
    } else if (isAndroid) {
      ;(window as any)?.android?.shareImageWithBase64(shareType, imageBase64)
    }
  },
  // 买一送一分享页 shareType: wechat-微信，moments-朋友圈，qq-QQ，saveImage-保存图片
  showTwoForOneShareDialog(orderId: String) {
    console.log('showTwoForOneShareDialog', orderId)
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.showTwoForOneShareDialog?.postMessage([orderId])
    } else if (isAndroid) {
      ;(window as any)?.android?.showTwoForOneShareDialog(orderId)
    }
  },
  // 绑定Steam
  toBindSteam(callbackUrl: String) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.toBindSteam?.postMessage([callbackUrl])
    } else if (isAndroid) {
      ;(window as any)?.android?.toBindSteam(callbackUrl)
    }
  },
  // 元宵礼箱大行动分享-定制
  shareNewYearGiftBox(url: String, title: String, des: String, imageUrl: String, imgDes: String, code: String, shortUrl: String) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.shareNewYearGiftBox?.postMessage([url, title, des, imageUrl, imgDes, code, shortUrl])
    } else if (isAndroid) {
      ;(window as any)?.android?.shareNewYearGiftBox(url, title, des, imageUrl, imgDes, code, shortUrl)
    }
  },
  // 友盟统计上报-paramsJson是hashmap的json字符串
  uMengOnEvent(eventId: String, paramsJson: String) {
    if (isiOS) {
      ;(window as any)?.webkit?.messageHandlers?.uMengOnEvent?.postMessage([eventId, paramsJson])
    } else if (isAndroid) {
      ;(window as any)?.android?.uMengOnEvent(eventId, paramsJson)
    }
  }
}

export default native
